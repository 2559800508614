
import * as Li9za2lrby5tanM from './skiko.mjs';
import * as _ref_QGpzLWpvZGEvY29yZQ_ from '@js-joda/core';
import { instantiate } from './ProSimWeb-compose-web.uninstantiated.mjs';
import "./custom-formatters.js"

const exports = (await instantiate({
    './skiko.mjs': Li9za2lrby5tanM,
    '@js-joda/core': _ref_QGpzLWpvZGEvY29yZQ_
})).exports;

export const {
    main,
    memory,
    _initialize,
    startUnitTests
} = exports;

